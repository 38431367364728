import { 
    GET_BUILDER_DATA,
    GET_BUILDER_DATA_SUCCESS,
    GET_BUILDER_DATA_FAILED,
    VIEW_BUILDER_DATA,
    VIEW_BUILDER_DATA_SUCCESS,
    VIEW_BUILDER_DATA_FAILED,
    UPDATE_BUILDER_DATA,
    UPDATE_BUILDER_DATA_SUCCESS,
    UPDATE_BUILDER_DATA_FAILED,
    CREATE_BUILDER_DATA,
    CREATE_BUILDER_DATA_SUCCESS,
    CREATE_BUILDER_DATA_FAILED,
    RESET_STATUS
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    builderLists: [],
    viewBuilderLists: [],
    updateSuccess: false,
    updateFailed: false,
    createSuccess: false,
    createFailed: false,
    deleteSuccess: false,
    deleteFailed : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BUILDER_DATA: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: '',
                builderLists: []
            }
        }

        case VIEW_BUILDER_DATA: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: '',
                viewBuilderLists: []
            }
        }

        case UPDATE_BUILDER_DATA: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: ''
            }
        }

        case CREATE_BUILDER_DATA: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: ''
            }
        }

        case RESET_STATUS: {
            return {
                ...state,
                updateSuccess: false,
                updateFailed : false,
                createSuccess: false,
                createFailed : false,
                deleteSuccess: false,
                deleteFailed : false,
            }
        }

        // response
        case GET_BUILDER_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                builderLists: action.payload
            }
        }

        case GET_BUILDER_DATA_FAILED: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                showMessage: true
            }
        }

        case VIEW_BUILDER_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                viewBuilderLists: action.payload
            }
        }

        case VIEW_BUILDER_DATA_FAILED: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
            }
        }

        case UPDATE_BUILDER_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                updateSuccess: true,
                updateFailed: false,
            }
        }

        case UPDATE_BUILDER_DATA_FAILED: {
            return {
                ...state,
                loader: false,
                updateSuccess: false,
                updateFailed: true,
            }
        }

        case CREATE_BUILDER_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                createSuccess: true,
                createFailed: false,
            }
        }

        case CREATE_BUILDER_DATA_FAILED: {
            return {
                ...state,
                loader: false,
                createSuccess: false,
                createFailed: true,
            }
        }

        default:
            return state;
    }
}